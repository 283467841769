/*----------------------------------------*/
/*  04. Element CSS
/*----------------------------------------*/

@import "elements/button";
@import "elements/tooltip";
@import "elements/accordion";
@import "elements/brand";
@import "elements/icon-box";
@import "elements/blockquote";
@import "elements/form";

/* moonlight */
@import "elements/animation";

.cart__mobile-card__card-text{
  font-size: 0.75rem;
  line-height: 12px;
}