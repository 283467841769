/*----------------------------------------*/
/*  15. Portfolio CSS
/*----------------------------------------*/

/* Portfolio */
.portfolio {
    position: relative;

    overflow: hidden;

    & .thumbnail {
        position: relative;

        &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            content: "";
            transition: $transition;

            opacity: 0;
            background-color: #F4EDE7;
        }

        & img {
            width: 100%;
        }
    }

    & .content {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 0;

        padding: 20px;

        transition: $transition;
        text-align: center;

        opacity: 0;
        opacity: 0;

        & .title {
            font-size: 20px;
            font-weight: 400;

            margin-bottom: 20px;

            & a {
                &:hover {
                    color: $heading-color;
                }
            }
        }

        & .desc {
            margin-bottom: 20px;

            & p {
                font-size: 16px;
                font-weight: 400;
            }
        }

        & .link {
            & a {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;

                padding-bottom: 2px;

                text-transform: uppercase;

                color: $heading-color;
                border-bottom: 1px solid rgba($heading-color, 0.2);

                &:hover {
                    border-color: $heading-color;
                }
            }
        }
    }

    &:hover {
        & .thumbnail {
            &::before {
                opacity: 1;
            }
        }

        & .content {
            transform: translateY(-50%);

            opacity: 1;
        }
    }

    @media #{$large-mobile} {
        & .content {
            position: absolute;
            z-index: 9;
            top: 50%;
            left: 0;

            padding: 20px;

            transition: $transition;
            text-align: center;

            opacity: 0;
            opacity: 0;

            & .title {
                margin-bottom: 10px;
            }

            & .desc {
                margin-bottom: 10px;
            }
        }
    }
}

/* Single Portfolio Image */
.portfolio-image {
    overflow: hidden;

    & img {
        width: 100%;

        transition: $transition;
    }

    &:hover {
        & img {
            transform: scale(1.1);
        }
    }
}

/* Single Portfolio Content */
.portfolio-content {
    & .title {
        font-weight: 400;

        margin-bottom: 25px;
    }

    & .desc {
        & p {
            font-size: 16px;

            margin-bottom: 20px;
        }
    }

    & .meta {
        margin: 0;
        padding-left: 0;

        list-style: none;

        & li {
            padding: 10px 0;

            border-top: 1px solid #EEEEEE;

            & span {
                &.name {
                    font-weight: 500;

                    color: $heading-color;
                }

                &.value {
                    &.category,
                    &.tags {
                        & a {
                            &:not(:last-child)::after {
                                margin-right: 1px;

                                content: ",";
                            }
                        }
                    }

                    &.social {
                        & a {
                            padding: 0 10px;
                        }
                    }

                    & a {
                        &:hover {
                            color: $heading-color;
                        }
                    }
                }
            }
        }
    }

    @media #{$small-mobile} {
        & .title {
            font-size: 24px;
        }
    }
}

/* Single Portfolio Overview */
.portfolio-overview {
    & p {
        font-size: 16px;
    }
}