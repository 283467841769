/* Site Menu Section */
.site-menu-section {
    border-top: 1px dashed #DEDEDE;
}

/* Site Main Menu */
.site-main-menu {
    font-weight: 400;

    display: flex;

    &>ul {
        font-size: 16px;

        display: flex;
        flex-wrap: wrap;

        margin-bottom: 0;
        padding-left: 0;

        list-style: none;

        &>li {
            // letter-spacing: 1.5px;

            &>a {
                line-height: 1.5;

                position: relative;

                display: flex;

                padding: 18px;

                text-transform: uppercase;

                color: $body-light;

                &>.menu-text {
                    position: relative;

                    &::after {
                        position: absolute;
                        bottom: -3px;
                        left: 0;

                        width: 100%;
                        height: 1px;

                        content: "";
                        transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
                        transform: scale3d(0, 1, 1);
                        transform-origin: 100% 50%;

                        background-color: $heading-color;
                    }
                }
            }

            &.has-children {
                &>a {
                    &::before {
                        font-family: $fontAwesome5Pro;
                        font-size: 10px;
                        position: absolute;
                        top: 50%;
                        right: 0px;
                        content: "\f078";
                        transform: translateY(-50%);
                        font-weight: 900;
                        opacity: 0.6;
                    }
                }
            }

            &.align-left {
                &>.sub-menu {
                    right: 0;
                    left: auto;
                }
            }

            &:hover {
                &>a {
                    color: $heading-color;

                    &>.menu-text {
                        &::after {
                            transform: scale3d(1, 1, 1);
                            transform-origin: 0 50%;
                        }
                    }
                }

                &>.sub-menu {
                    z-index: 99;
                    top: 85%;

                    visibility: visible;

                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    &.menu-height-100 {
        &>ul {
            &>li {
                &>a {
                    padding-top: 38px;
                    padding-bottom: 38px;
                }
            }
        }
    }

    &.menu-height-60 {
        &>ul {
            &>li {
                &>a {
                    padding-top: 18px;
                    padding-bottom: 18px;
                }
            }
        }
    }
}

/* Sub Menu */
.sub-menu {
    position: absolute;
    z-index: 9;
    top: 150%;

    visibility: hidden;

    min-width: 280px;
    margin: 0;
    padding: 20px 0;

    list-style: none;

    transition: $transition;
    letter-spacing: 0;

    opacity: 0;
    background-color: $white;
    box-shadow: -1px 10px 80px -15px rgba($black, 0.3);

    &.sub-menu--pointer-default {
        pointer-events: none;
    }

    & li {
        line-height: 3.125;

        position: relative;

        & a {
            position: relative;

            display: block;

            padding: 0 30px;

            color: $body-light;

            &:not(.mega-menu-title) {
                & .menu-text {
                    position: relative;

                    &::after {
                        position: absolute;
                        top: calc(50% + 10px);
                        left: 0;

                        width: 100%;
                        height: 1px;

                        content: "";
                        transition: $transition;

                        opacity: 0;
                        background-color: $heading-color;
                    }
                }
            }

            &:hover {
                & .menu-text {
                    &::after {
                        opacity: 0.2;
                    }
                }
            }
        }

        &.has-children {
            &>a {
                &::before {
                    font-family: $fontAwesome5Pro;
                    font-size: 12px;

                    position: absolute;
                    top: 50%;
                    right: 30px;
                    font-weight: 900;
                    content: "\f105";
                    transform: translateY(-50%);

                    opacity: 0.6;
                }
            }

            &.align-left {
                &>a {
                    &::before {
                        content: "\f104";
                    }
                }

                &>.sub-menu {
                    right: 100%;
                    left: auto;
                }
            }
        }

        &>.sub-menu {
            top: 100%;
            left: 100%;
        }

        &:hover {
            &>a {
                color: $heading-color;
            }

            &>.sub-menu {
                top: 0;

                visibility: visible;

                opacity: 1;
            }
        }
    }

    &.mega-menu {
        right: auto;
        left: 15px;

        display: flex;
        flex-wrap: wrap;
        pointer-events: none;

        width: 1170px;
        margin: 0;
        padding: 30px 30px 30px 10px;

        list-style: none;

        &>li {
            flex: 1 0 25%;

            padding: 0 10px 0 30px;

            border-right: 1px solid #E7E7E7;

            &:last-child {
                border: none;
            }

            &>a {
                padding: 0;

                &.mega-menu-title {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.2;

                    margin-bottom: 16px;

                    letter-spacing: 0.01em;

                    color: $heading-color;
                }
            }

            &:hover {
                &>a {
                    color: $heading-color;
                }
            }

            & ul {
                margin: 0;
                padding: 0;

                list-style: none;

                & li {
                    line-height: 3.125;

                    position: relative;

                    & a {
                        padding: 0;

                        color: $body-light;
                    }

                    &>img {
                        position: absolute;
                        z-index: 99;
                        top: 50%;
                        left: 40%;

                        visibility: hidden;

                        width: 250px;
                        height: auto;

                        transform: translateY(-40%);

                        opacity: 0;
                        border-radius: 5px;
                        box-shadow: 0 10px 25px rgba($black, 0.2);
                    }

                    &:hover {
                        &>a {
                            color: $heading-color;
                        }

                        &>img {
                            visibility: visible;

                            transform: translateY(-50%);

                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}