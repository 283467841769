/* Category Banner Style One CSS */
.category-banner1 {
    position: relative;

    display: block;
    float: left;

    width: 100%;

    & .inner {
        & .image {
            position: relative;

            display: block;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &::before {
                position: absolute;
                z-index: 2;
                top: 15px;
                left: 15px;

                width: calc(100% - 30px);
                height: calc(100% - 30px);

                content: "";

                border: 1px solid $white;
            }

            & img {
                width: 100%;
            }
        }

        & .content {
            & .title {
                font-size: 24px;
                font-weight: 400;

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                margin: 0;

                & a {
                    &:hover {
                        color: $heading-color;
                    }
                }

                & .number {
                    font-family: $futura;
                    font-size: 16px;
                    line-height: 1;

                    position: relative;

                    align-self: center;

                    color: $heading-color;

                    &::before {
                        position: absolute;
                        top: calc(50% + 2px);
                        right: calc(100% + 10px);

                        width: 50px;
                        height: 1px;

                        content: "";

                        background-color: #CCCCCC;
                    }
                }
            }
        }
    }

    &:hover {
        & .inner {
            & .image {
                & img {
                    transform: scale(1.1);
                }
            }
        }
    }
}