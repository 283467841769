/* 18.02. About Page CSS */

/* About us Style One */
.about-us {
    text-align: center;

    & .inner {
        & .logo {
            margin-bottom: 40px;
        }

        & .title {
            font-size: 40px;
            font-weight: 400;

            &::before {
                padding-right: 10px;

                content: url("../images/title/title-decor-left.webp");
            }

            &::after {
                padding-left: 10px;

                content: url("../images/title/title-decor-right.webp");
            }

            &.no-shape {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        & .special-title {
            font-family: $modesty;
            font-size: 56px;
            line-height: 1.25;

            display: block;

            margin-bottom: 5px;

            color: $primary2;
        }

        & p {
            margin-bottom: 35px;
        }

        & .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.25;

            text-transform: uppercase;

            color: $heading-color;
            border-bottom: 1px solid rgba($heading-color, 0.2);

            &:hover {
                border-color: $heading-color;
            }
        }
    }

    @media #{$desktop-device} {
        & .inner {
            & .title {
                font-size: 28px;
            }
        }
    }

    @media #{$small-mobile} {
        & .inner {
            & .title {
                font-size: 28px;

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}

/* About us Style Two */
.about-us2 {
    text-align: center;

    & .inner {
        & .title {
            font-family: append($charmonman, $font-family-base);
            font-size: 60px;
            font-weight: 400;

            margin-bottom: 15px;

            &::before {
                padding-right: 10px;

                content: url("../images/title/title-decor-left.webp");
            }

            &::after {
                padding-left: 10px;

                content: url("../images/title/title-decor-right.webp");
            }
        }

        & .sub-title {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;

            display: block;

            margin-bottom: 25px;

            letter-spacing: 5px;

            color: $primary2;
        }

        & .desc {
            & p {
                font-size: 16px;
                line-height: 2;
            }
        }
    }

    @media #{$tablet-device} {
        & .inner {
            & .title {
                font-size: 60px;
            }
        }
    }

    @media #{$large-mobile} {
        & .inner {
            & .title {
                font-size: 50px;
            }
        }
    }

    @media #{$small-mobile} {
        & .inner {
            & .title {
                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}

/* About us Style Three */
.about-us3 {
    & .sub-title {
        font-size: 18px;
        font-weight: 500;

        display: block;

        margin-bottom: 20px;

        color: $heading-color;
    }

    & .title {
        font-size: 40px;
        font-weight: 400;
        line-height: 1.4;

        margin-bottom: 20px;
    }

    & .desc {
        margin-bottom: 35px;

        & p {
        }
    }

    & .link {
        font-weight: 500;
        line-height: 1.25;

        position: relative;

        display: inline-flex;
        align-items: center;

        color: $heading-color;

        &::before {
            width: 60px;
            height: 2px;
            margin-right: 10px;

            content: "";
            transition: $transition;

            background-color: $heading-color;
        }

        &:hover {
            color: $primary;

            &::before {
                width: 70px;
            }
        }
    }

    @media #{$small-mobile} {
        & .title {
            font-size: 30px;
        }

        & .desc {
            margin-bottom: 20px;
        }
    }
}

/* About us Style Four */
.about-us4 {
    & .sub-title {
        font-size: 16px;
        font-weight: 500;

        display: block;

        margin-bottom: 20px;

        letter-spacing: 2px;

        color: $heading-color;
    }

    & .title {
        font-size: 56px;
        font-weight: 400;
        line-height: 1.25;

        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & .desc {
        margin-bottom: 35px;

        & p {
        }
    }

    @media #{$large-mobile} {
        & .title {
            font-size: 40px;
        }
    }

    @media #{$small-mobile} {
        & .title {
            font-size: 30px;
        }
    }
}