/* Sale Banner Style Ten CSS */
.sale-banner10 {
    & .inner {
        position: relative;

        overflow: hidden;

        & > img {
            width: 100%;
        }

        & .content {
            position: absolute;
            top: 50px;
            left: 0;

            width: 100%;
            padding: 0 20px;

            text-align: center;

            & .sub-title {
                font-family: $modesty;
                font-size: 56px;
                line-height: 1;

                display: block;

                margin-bottom: 20px;

                color: $primary2;
            }

            & .title {
                font-size: 34px;
                font-weight: 400;
                line-height: 1;

                letter-spacing: 4px;
            }

            & img {
            }
        }
    }

    &:hover {
        & .inner {
            & > img {
                transform: scale(1.1);
            }
        }
    }
}