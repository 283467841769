/* 03.02. Ion Range Slider */

.irs--learts {
    font-family: $futura;

    margin: 7px 0 40px;

    & .irs {
        font-family: $futura;

        position: relative;

        width: 100%;

        & .irs-line {
            width: 100%;
            height: 5px;

            background-color: #EEEEEE;
        }

        & .irs-min {
        }

        & .irs-max {
        }

        & .irs-from,
        & .irs-to {
            display: none !important;
        }

        & .irs-from {
        }

        & .irs-to {
        }

        & .irs-single {
            font-size: 14px;
            font-weight: 400;
            line-height: 1;

            position: absolute;
            top: calc(100% + 25px);
            left: 0 !important;

            visibility: visible !important;

            &::before {
                content: "Price: ";
            }
        }
    }

    & .irs-bar {
        top: 0;

        height: 5px;

        background-color: $heading-color;
    }

    & .irs-handle {
        top: 50%;

        width: 18px;
        height: 18px;

        transform: translateY(-50%);
        text-indent: -9999px;

        border-radius: 50%;
        background-color: $heading-color;

        &.from {
        }

        &.to {
        }
    }
}