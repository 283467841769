/* Sale Banner Style One CSS */
.sale-banner1 {
    position: relative;

    width: 100%;
    max-width: 510px;
    margin: auto;
    padding-bottom: 100%;

    & .inner {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        height: 100%;

        & img {
        }

        & .title {
            font-family: $modesty;
            font-size: 80px;
            font-weight: 300;
            line-height: 1;

            margin-bottom: 3px;

            color: $heading-color;
        }

        & .sale-percent {
            font-size: 34px;
            font-weight: 300;
            line-height: 1;

            display: flex;
            align-items: center;

            max-width: 200px;
            margin-bottom: 30px;

            text-align: center;
            text-transform: uppercase;

            & .number {
                font-size: 100px;
            }
        }

        & .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 1;

            padding: 2px 0;

            text-transform: uppercase;

            color: $heading-color;
            border-bottom: 1px solid transparent;

            &:hover {
                border-color: rgba($heading-color, 0.2);
            }
        }
    }

    @media #{$desktop-device} {
        & .inner {
            transform: scale(0.8);
        }
    }

    @media #{$tablet-device,
    $extra-small-mobile
    } {
        & .inner {
            transform: scale(0.7);

            & .link {
                font-size: 20px;
            }
        }
    }
}