/* 09.04. Quick View CSS */

.quickViewModal {

    .small,
    small {
        font-size: 14px;
        line-height: 1.3;
    }

    & .modal-dialog {
        width: calc(100% - 30px);
        max-width: 900px;

        & .modal-content {
            padding: 30px;

            & .close {
                font-size: 30px;
                font-weight: 300;

                position: absolute;
                top: 0;
                right: 0;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 40px;
                height: 40px;

                border: none;
                border-radius: 50%;
                background-color: transparent;
            }

            .product-title {
                font-size: 18px;
            }

            .product-price {
                font-size: 20px;
            }

            .product-price,
            .product-title,
            .product-variations {
                margin-bottom: .5rem;
            }

            .product-meta {
                padding-top: 8px;

                .tr-manufacturer,
                .tr-delivery,
                .tr-mail small {
                    @extend .d-none;
                }
            }

            .product-description {
                *:not(:first-child) {
                    @extend .d-none;
                }

                // *:first-child {
                //     @extend .text-nowrap;
                //     text-overflow: ellipsis;
                //     @extend .overflow-hidden;
                //     @extend .mb-0;
                // }
            }

            .product-meta table tbody tr td.label,
            .product-variations table tbody tr td.label {
                min-width: 85px;
                @extend .pe-1;
            }

            .product-variants__option-buttons {
                letter-spacing: normal;
                @extend .fw-300;
                @extend .p-1;
            }

            .product-buttons,
            .btn-fav,
            .btn-add-to-cart {
                @extend .mb-0;
            }

            .product-buttons {
                @extend .align-items-center;
                @extend .d-flex;
                @extend .product-buttons;
            }

            .btn-fav {
                width: 35px;
                height: 35px;
            }

            .btn-add-to-cart {
                @extend .py-1;
            }

            @media only screen and (min-width: 992px) {
                & .product-summery {
                    /* This will make thumb on the left, and scroll the summary only (theme original one) */
                    // position: absolute;
                    // right: 15px;
                    // left: 15px;

                    // overflow-x: hidden;
                    // overflow-y: auto;

                    // height: 100%;
                }
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .modal-dialog {
            & .modal-content {
                padding: 20px;
            }
        }
    }
}

.product-gallery-slider-quickview {
    margin: 0;
    padding: 0;

    & .slick-list {
        margin: 0;

        & .slick-slide {
            &>div {
                padding: 0;
            }
        }
    }

    & .slick-dots {
        position: absolute;
        bottom: 40px;
        left: 0;

        width: 100%;
    }
}