/* Header Tools/Action Buttons */
.header-tools {
  display: flex;

  & > div {
    margin-left: 25px;

    & a {
      position: relative;

      display: flex;

      color: $heading-color;

      & span {
        font-size: 13px;
        font-weight: 700;
        line-height: 1rem;

        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;

        min-width: 16px;
        height: 16px;
        padding: 0 3px;

        text-align: center;

        color: $white;
        border-radius: 14px;
        background-color: $primary;
      }

      & i {
        font-size: 20px;
        line-height: 32px;

        display: block;
      }
    }

    &.header-wishlist,
    &.header-cart {
      & > a {
        & i {
          margin-right: 10px;
        }
      }
    }

    &.mobile-menu-toggle {
      & > a {
        width: 24px;
        height: 32px;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    .header-login-menu {
      margin: 0;
      padding: 30px 40px 30px 40px;
    }

    &.header-login:hover .header-login-menu {
      opacity: 1;
      pointer-events: auto;
      top: 32px;
      visibility: visible;
      z-index: 99;
    }
    .sub-menu__item {
      color: #7e7e7e;
      line-height: 3.125;
      font-size: 16px;
      font-weight: 400;
      .sub-menu__item__decoration {
        position: relative;
        &::after {
          background-color: #333;
          content: "";
          height: 1px;
          left: 0;
          opacity: 0;
          position: absolute;
          top: calc(50% + 10px);
          transition: all 0.5s ease 0s;
          width: 100%;
        }
      }
      &:hover .sub-menu__item__decoration::after {
        opacity: 0.2;
      }
    }
  }

  @media #{$small-mobile} {
    & > div {
      margin-left: 15px;
    }
  }
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 50px;
    height: 60px;
    margin-top: -2px;
    margin-left: -2px;

    cursor: pointer;
    transform: translate3d(-50%, -50%, 0);

    & path {
      transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
        stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);

      fill: none;
      stroke: $heading-color;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 30px;

      &.top,
      &.bottom {
        stroke-dasharray: 240px 950px;
      }

      &.middle {
        stroke-dasharray: 240px 240px;
      }
    }
  }

  & .close {
    opacity: 1;

    & svg {
      & path {
        &.top,
        &.bottom {
          stroke-dasharray: -650px;
          stroke-dashoffset: -650px;
        }

        &.middle {
          stroke-dasharray: 1px 220px;
          stroke-dashoffset: -115px;
        }
      }
    }
  }
}
