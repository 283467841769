/* 18.01.07. Lost Password CSS */
.lost-password {
    max-width: 500px;
    margin: auto;
    padding: 40px;

    background-color: $light;

    & p {
        font-size: 16px;
        font-weight: 400;

        margin-bottom: 30px;
    }

    & input {
        font-weight: 400;

@include placeholder {
            font-weight: 400;
        }
    }

    @media #{$small-mobile} {
        padding: 40px;
    }

    @media #{$extra-small-mobile} {
        padding: 30px;
    }
}