@keyframes expandWidth {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.width-animated {
  width: 0%; /* Starting width */
  animation: expandWidth 2s ease-in-out forwards; /* 2s duration, ease-in-out timing function, forwards to keep the final width */
}