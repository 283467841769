/* Sale Banner Style Seven CSS */
.sale-banner7 {
    height: 100%;

    & .inner {
        position: relative;

        height: 100%;

        & .image {
            overflow: hidden;

            width: 100%;
            height: 100%;

            & img {
                z-index: 1;

                display: block;

                width: 100%;
                height: 100%;

                object-fit: cover;
            }

            &::before {
                position: absolute;
                z-index: 2;
                top: 15px;
                left: 15px;

                width: calc(100% - 30px);
                height: calc(100% - 30px);

                content: "";
                transition: $transition;

                opacity: 0;
                border: 1px solid $white;
            }
        }

        & .content {
            position: absolute;
            z-index: 9;
            top: 15%;
            left: 0;

            width: 100%;

            text-align: center;


            & .title {
                font-family: $charmonman;
                font-size: 80px;
                font-weight: 400;
                line-height: 1;

                margin-bottom: 10px;

                color: $body-color;
            }

            & .sub-title {
                font-family: $futura;
                font-size: 34px;
                font-weight: 700;
                line-height: 1;

                margin-bottom: 0;

                letter-spacing: 10px;
                text-transform: uppercase;

                color: $primary3;
            }
        }
    }

    &:hover {
        & .inner {
            & .image {
                & img {
                    transform: scale(1.1);
                }

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    @media #{$desktop-device,
    $small-mobile
    } {
        & .inner {
            & .content {
                & .title {
                    font-size: 60px;
                }

                & .sub-title {
                    font-size: 24px;
                }
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .inner {
            & .content {
                & .title {
                    font-size: 40px;
                }

                & .sub-title {
                    font-size: 20px;
                }
            }
        }
    }
}