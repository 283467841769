/* Category Banner Style Two CSS */
.category-banner2 {
    position: relative;

    display: block;
    float: left;

    width: 100%;

    & .inner {
        display: block;

        & .image {
            position: relative;

            display: block;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &::before {
                position: absolute;
                z-index: 2;
                top: 15px;
                left: 15px;

                width: calc(100% - 30px);
                height: calc(100% - 30px);

                content: "";
                transition: $transition;

                opacity: 0;
                border: 1px solid $white;
            }

            & img {
                width: 100%;
            }
        }

        & .content {
            & .title {
                font-size: 34px;
                font-weight: 400;

                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                margin: 0;

                & .number {
                    font-family: $futura;
                    font-size: 16px;
                    line-height: 1;

                    position: relative;

                    padding-left: 5px;

                    color: $heading-color;
                }
            }
        }
    }

    & .banner-desc {
        font-family: $futura;
        font-size: 16px;
        font-weight: 600;

        position: absolute;
        top: 50%;

        transform: translateY(-50%);
        white-space: nowrap;
        letter-spacing: 3px;

        color: $body-color;

        writing-mode: vertical-rl;

        &.right {
            right: -40px;
        }

        &.left {
            left: -40px;
        }
    }

    &:hover {
        & .inner {
            & .image {
                & img {
                    transform: scale(1.1);
                }

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    @media #{$tablet-device,
    $large-mobile
    } {
        & .inner {
            & .content {
                & .title {
                    font-size: 24px;
                }
            }
        }

        & .banner-desc {
            display: none;
        }
    }
}