/* Sale Banner Style Eight CSS */
.sale-banner8 {
    position: relative;

    display: block;
    overflow: hidden;

    & img {
        display: block;

        width: 100%;
    }

    & .content {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 40px;

        transform: translateY(-50%);

        & .title {
            font-size: 34px;
            font-weight: 400;
            line-height: 1.35;

            margin-bottom: 10px;
        }

        & .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;

            display: inline-block;

            padding-bottom: 5px;

            letter-spacing: 1.5px;
            text-transform: uppercase;

            color: $heading-color;
            border-bottom: 1px solid rgba($heading-color, 0.2);

            &:hover {
                border-color: $heading-color;
            }
        }
    }

    &:hover {
        & img {
            transform: scale(1.1);
        }
    }

    @media #{$desktop-device} {
        & .content {
            & .title {
                font-size: 30px;
            }

            & .link {
                font-size: 14px;
            }
        }
    }

    @media #{$small-mobile} {
        & .content {
            & .title {
                font-size: 24px;
            }

            & .link {
                font-size: 14px;
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .content {
            left: 20px;

            & .title {
                font-size: 18px;

                margin-bottom: 6px;
            }

            & .link {
                font-size: 12px;
            }
        }
    }
}