/* Overlay Menu */
.overlay-menu {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    margin: 0 -40px;

    & > ul {
        font-size: 32px;

        overflow-x: auto;

        width: 100%;
        max-height: 100%;
        margin-bottom: 0;
        padding: 0 40px;

        list-style: none;

        text-align: center;

        & > li {
            position: relative;

            & + li {
                margin-top: 15px;
            }

            & > a {
                font-family: $futura;
                font-weight: 400;
                line-height: 1.75;

                text-transform: uppercase;

                color: $heading-color;
            }
        }

        & .sub-menu {
            font-size: 75%;

            position: static;

            display: none;
            visibility: visible;

            padding-bottom: 0;

            transition: none;

            opacity: 1;
            background-color: transparent;
            box-shadow: none;

            & > li {
                & + li {
                    margin-top: 15px;
                }

                & > a {
                    font-family: $futura;
                    font-weight: 400;
                    line-height: 1.75;

                    text-transform: uppercase;
                }
            }

            & .sub-menu {
                font-size: 70%;
            }
        }
    }

    @media #{$small-mobile} {
        margin: 0 -30px;

        & > ul {
            padding: 0 30px;
        }
    }

    @media #{$extra-small-mobile} {
        margin: 0 -20px;

        & > ul {
            padding: 0 20px;
        }
    }
}