/* 18.01.03. Cart & Wishlist CSS */

/* Cart & Wishlist Table */
.cart-wishlist-table {
  margin-bottom: 25px;

  border: 1px solid #ededed;

  & thead {
    & tr {
      & th {
        padding: 12px 16px;

        white-space: nowrap;

        border-top: none;
        border-bottom: 1px solid #ededed !important;

        &:first-child {
          padding-left: 25px;
        }
      }
    }
  }

  & tbody {
    & tr {
      position: relative;

      & td {
        padding: 25px 10px 25px 25px;

        vertical-align: middle;

        border-color: #ededed;

        &.thumbnail {
          width: 110px;
          min-width: 110px;

          & a {
            display: block;
          }
        }

        &.name {
          & a {
            font-size: 16px;
            font-weight: 400;

            color: $body-color;

            &:hover {
              color: $heading-color;
            }
          }
        }

        &.price,
        &.subtotal {
          font-size: 14px;
          font-weight: 600;
        }

        &.remove {
          & .btn {
            font-size: 24px;

            padding: 5px 10px;

            color: $body-color;

            &:hover {
              color: $danger;
            }
          }
        }
      }
    }
  }

  @media #{$large-mobile} {
    & thead {
      display: none;
    }

    & tbody {
      & tr {
        & td {
          display: block;

          width: 100% !important;
          margin-bottom: 15px;
          padding: 0;

          text-align: center;

          &:not(:first-child) {
            border-top: none;
          }

          &.thumbnail {
            padding-top: 20px;
          }

          & .product-quantity {
            margin: auto;
          }

          &.remove {
            position: absolute;
            z-index: 9;
            top: 0;
            right: 0;

            width: auto !important;
          }
        }

        &:first-child {
          & td {
            &:first-child {
              border-top: none;
            }
          }
        }
      }
    }
  }
}

/* Cart Coupon */
.cart-coupon {
  position: relative;

  & input[type="text"] {
    width: 300px;
    padding-right: 30px;
  }

  & .btn {
    position: absolute;
    top: 0;
    right: 0;

    padding: 12px 0;

    & i {
      font-size: 20px;
    }
  }
}

/* Cart Totals */
.cart-totals {
  float: right;

  width: 400px;
  padding: 40px 30px;

  text-align: center;

  background-color: #f7f7f7;

  & .title {
    margin-bottom: 25px;
  }

  & table {
    width: 100%;
    margin-bottom: 30px;

    & tbody {
      & tr {
        font-family: $futura;
        font-weight: 700;

        color: $heading-color;

        &.subtotal {
          & td {
            font-size: 14px;
          }
        }

        &.total {
          & td {
            font-size: 18px;
          }
        }

        & th {
          font-size: 18px;

          padding: 15px 10px;

          text-align: left;
        }

        & td {
          padding: 15px 10px;

          text-align: right;
        }
      }
    }
  }

  & .btn {
  }

  @media #{$tablet-device,} {
    width: 100%;
  }
}

/* moonlght pet store */
.table__thumbnail--width {
  width: 110px;
  min-width: 110px;
}
.cart__ffp-section {
  --bs-card-spacer-y: 5px;
  --bs-card-spacer-x: 5px;
  .btn-collapse {
    text-transform: none;
  }
  .collapse-title {
    color: #198754;
    font-family: $futura;
  }
}

.addon-section,
.cart__ffp-section {
  [data-style="pre-order"] {
    display: none;
  }
}
