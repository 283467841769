/* 03.03. Parfect Scrollbar */

.ps {
    position: relative;

    padding-right: 10px;

    &:not(.ps--active-y) {
        overflow: visible !important;
    }

    & .ps__scrollbar-y-rail {
        width: 5px;

        opacity: 0;
        border-radius: 5px;

        & .ps__scrollbar-y {
            right: 1px;

            width: 3px !important;

            transition: all 0.1s ease 0s;

            border-radius: 5px;
        }
    }
}