/* Sale Banner Style Four CSS */
.sale-banner4 {
    height: 100%;

    & .inner {
        position: relative;

        height: 100%;

        & > img {
            z-index: 1;

            display: block;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        & .content {
            position: absolute;
            z-index: 9;
            top: 15%;
            left: 0;

            width: 100%;

            text-align: center;

            & .sub-title {
                font-size: 24px;
                font-weight: 400;
                line-height: 1;

                margin-bottom: 25px;
            }

            & .title {
                font-family: $charmonman;
                font-size: 80px;
                font-weight: 400;
                line-height: 0.9;

                margin-bottom: 40px;
            }

            & .button-banner {
                & img {
                }
            }
        }
    }

    @media #{$desktop-device,
    $small-mobile
    } {
        & .inner {
            & .content {
                & .sub-title {
                    font-size: 20px;

                    margin-bottom: 20px;
                }

                & .title {
                    font-size: 60px;

                    margin-bottom: 30px;
                }
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .inner {
            & .content {
                top: 12%;

                & .sub-title {
                    font-size: 18px;

                    margin-bottom: 15px;
                }

                & .title {
                    font-size: 40px;

                    margin-bottom: 20px;
                }

                & .button-banner {
                    & img {
                        height: 16px;
                    }
                }
            }
        }
    }
}