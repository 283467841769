/* Sale Banner Style Six CSS */
.sale-banner6 {
    & .inner {
        position: relative;

        display: block;

        & > img {
            z-index: 1;

            display: block;

            width: 100%;
        }

        & .content {
            position: absolute;
            z-index: 9;
            top: 50%;
            left: 0;

            width: 100%;

            transform: translateY(-50%);
            text-align: center;

            & img {
                display: block;

                margin: auto;

                &.icon {
                }

                &.price {
                    margin-bottom: 25px;
                }
            }

            & .title {
                font-family: $charmonman;
                font-size: 80px;
                font-weight: 400;
                line-height: 0.9;

                margin-bottom: 20px;
            }

            & .link {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.3;

                display: inline-block;

                padding-bottom: 5px;

                letter-spacing: 1.5px;
                text-transform: uppercase;

                color: $heading-color;
                border-bottom: 1px solid rgba($heading-color, 0.2);

                &:hover {
                    border-color: $heading-color;
                }
            }
        }
    }

    @media #{$laptop-device} {
        & .inner {
            & .content {
                & .title {
                    font-size: 60px;
                }

                & img {
                    &.icon {
                        height: 50px;
                    }

                    &.price {
                        height: 50px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    @media #{$desktop-device,
    $large-mobile

    } {
        & .inner {
            & .content {
                & .title {
                    font-size: 60px;
                }

                & img {
                    &.icon {
                        height: 40px;
                    }

                    &.price {
                        height: 30px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .inner {
            & .content {
                & .title {
                    font-size: 40px;

                    margin-bottom: 10px;
                }

                & img {
                    &.icon {
                        height: 30px;
                    }

                    &.price {
                        height: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}