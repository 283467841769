.guide-home {
  .anchor-section {
    &__wrapper {
      margin-top: 24px;
      margin-bottom: 48px;
      @include media-breakpoint-up(lg) {
        margin-top: 90px;
        margin-bottom: 80px;
      }
    }
    &__title {
      font-size: px-to-rem(10px);
      margin-bottom: 12px;
      @include media-breakpoint-up(lg) {
        font-size: px-to-rem(16px);
        margin-bottom: 24px;
      }
    }
    &__btn-row {
      column-gap: 8px;
      > *:last-child {
        margin-right: auto;
      }
      > *:first-child {
        margin-left: auto;
      }
    }
    &__btn {
      font-size: px-to-rem(14px);
      padding: 13px;
      padding-left: 18px;
      padding-right: 18px;
      @include media-breakpoint-up(lg) {
        padding: 12px 20px;
      }
    }
  }
  .guide--outer-wrapper {
    margin-bottom: 48px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }
  }

  .guide-title {
    &__wrapper {
      margin-bottom: px-to-rem(28px);
      @include media-breakpoint-up(lg) {
        margin-bottom: px-to-rem(56px);
      }
    }
    &__anchor {
      width: 20px;
      height: 20px;
      top: -110px;
    }
    &__title {
      font-weight: 500;
      margin-bottom: px-to-rem(24px);
      font-size: px-to-rem(24px);
      @include media-breakpoint-up(lg) {
        font-size: px-to-rem(40px);
        margin-bottom: px-to-rem(32px);
      }
    }
    &__description {
      font-size: px-to-rem(14px);
      @include media-breakpoint-up(lg) {
        font-size: px-to-rem(18px);
      }
    }
  }

  .guide-card {
    &__image {
      transition: 0.5s;
    }
    &:hover {
      .guide-card__image {
        border-radius: 2.5rem;
      }
      .guide-card__btn {
        @extend .btn-primary;
      }
    }

    &__texts-wrapper {
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include media-breakpoint-up(lg) {
        &.lg--vertical-start-horizontal-center {
          padding: 86px 0px;
          justify-content: start;
        }
        &.lg--vertical-center-horizontal-start {
          padding: 0px 48px;
          text-align: left;
          align-items: start;
        }
        &.lg--vertical-start-horizontal-start {
          padding: 86px 48px 0px;
          text-align: left;
          align-items: start;
          justify-content: start;
        }
      }
      line-height: normal;
    }

    &__title {
      margin-bottom: 14px;
      font-family: $marcellus;
      font-size: px-to-rem(21px);
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
        font-size: px-to-rem(40px);
      }
      &.small-title {
        font-size: px-to-rem(18px);
        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(24px);
        }
      }
      &.sm-small-lg-large-title {
        font-size: px-to-rem(18px);
        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(40px);
        }
      }
    }
    &__subtitle {
      font-size: px-to-rem(12px);
      margin-bottom: 14px;
      @include media-breakpoint-up(lg) {
        @include line-clamp(2);
        font-size: px-to-rem(18px);
        margin-bottom: 1.5rem;
      }
      &.small-subtitle {
        font-size: px-to-rem(12px);
        @include media-breakpoint-up(lg) {
          font-size: px-to-rem(16px);
        }
      }
    }
    &__description {
      margin-bottom: 14px;
      font-family: append("Rubik", $font-family-base);
      font-size: px-to-rem(10px);
      @include media-breakpoint-up(lg) {
        @include line-clamp(2);
        margin-bottom: 32px;
        font-size: px-to-rem(16px);
      }
    }
    &__btn {
      @extend .btn-sm;
      transform: scale(0.9);
      padding: 8px 16px;
      line-height: normal;
      text-transform: none;
      &.cursor-default:hover {
        cursor: default;
      }
      @include media-breakpoint-up(lg) {
        transform: none;
        padding: 12px 20px;
      }
    }
  }
}
