/* 09.03. Single Product CSS */
.product-detail {
  .smaller {
    font-size: 20.4px;
  }
  small {
    font-size: 14px;
    line-height: 1.3;
  }
  .btn-fav {
    &:focus {
      color: $primary;
    }
    &:hover {
      color: white;
    }
  }
}

/* Product Image */
.product-images {
  position: relative;

  &.vertical {
    padding-left: 210px;
  }

  @media #{$laptop-device} {
    &.vertical {
      padding-left: 140px;
    }
  }

  @media #{$desktop-device} {
    &.vertical {
      padding-left: 110px;
    }
  }

  @media #{$tablet-device} {
    &.vertical {
      padding-left: 155px;
    }
  }

  @media #{$large-mobile} {
    &.vertical {
      padding-left: 110px;
    }
  }

  @media #{$small-mobile} {
    &.vertical {
      padding-left: 95px;
    }
  }

  @media #{$extra-small-mobile} {
    &.vertical {
      padding-left: 75px;
    }
  }
}

/* Product Gallery Popup */
.product-gallery-popup {
  position: absolute;
  z-index: 9;
  top: 20px;
  right: 20px;

  width: 30px;
  padding: 0;

  text-align: center;

  color: $heading-color;
  border: none;
  background-color: transparent;

  & i {
    font-size: 24px;
    line-height: 1;

    display: block;
  }
}

/* Product Video Popup */
.product-video-popup {
  position: absolute;
  z-index: 9;
  top: 70px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  color: $heading-color;
  border: 1px solid $heading-color;
  border-radius: 50%;

  & i {
    font-size: 10px;
    line-height: 1;

    margin-left: 3px;
  }

  &:hover {
    transform: scale(1.1);

    color: $heading-color;
  }
}

/* Product 360 View Toggle */
.toggle-360 {
  position: absolute;
  z-index: 9;
  top: 70px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  border-radius: 50%;

  &:hover {
    transform: scale(1.1);
  }
}

/* Product Gallery Slider */
.product-gallery-slider {
  margin: 0;
  padding: 0;

  & .slick-slide {
    & > div {
      display: flex;
    }
  }

  & .slick-dots {
    position: absolute;
    bottom: 40px;
    left: 0;

    width: 100%;
  }
}

/* Product Zoom */
.product-zoom {
  display: block !important;

  & img {
    &:not(.zoomImg) {
      width: 100%;
    }
  }
}

/* Product Thumbnail Slider */
.product-thumb-slider {
  margin: 30px 0 0;
  padding: 0;

  & .slick-list {
    margin: 0 -15px;

    & .slick-slide {
      padding: 0 15px;

      & > div {
        display: flex;
      }

      &.slick-current {
        & .item {
          border-color: $heading-color;
        }
      }
    }
  }

  & .item {
    margin-bottom: 1px;

    border: 1px solid transparent;

    & img {
      width: 100%;
    }
  }
}

/* Product Thumbnail Slider Vertical */
.product-thumb-slider-vertical {
  position: absolute;
  top: 0;
  left: 0;

  width: 195px;
  margin: 0;
  padding: 0 0 75px;

  & .slick-arrow {
    top: auto;
    bottom: 0;

    width: calc(50% - 3px);
    height: 30px;

    transform: translateY(0);

    opacity: 0.7;
    color: $white;
    border-radius: 500px;
    background-color: #7e7e7e;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 1px;
    }

    & i {
      font-size: 14px;
    }
  }

  &:hover {
    & .slick-arrow {
      &.slick-next {
        right: 1px;
      }
    }
  }

  & .slick-list {
    margin: 0 0 -10px;

    & .slick-slide {
      padding: 0 0 10px;

      & > div {
        display: flex;
      }

      &.slick-current {
        & .item {
          border-color: $heading-color;
        }
      }
    }
  }

  & .item {
    margin-bottom: 1px;

    border: 1px solid transparent;

    & img {
      width: 100%;
    }
  }

  @media #{$laptop-device} {
    width: 125px;
    padding-bottom: 60px;
  }

  @media #{$desktop-device} {
    width: 95px;
    padding-bottom: 60px;
  }

  @media #{$tablet-device} {
    width: 140px;
    padding-bottom: 60px;
  }

  @media #{$large-mobile} {
    width: 95px;
    padding-bottom: 60px;
  }

  @media #{$small-mobile} {
    width: 80px;
    padding-bottom: 75px;

    & .slick-arrow {
      right: 0;
      left: 0;

      width: 100% !important;

      &.slick-prev {
        bottom: 35px;
      }
    }
  }

  @media #{$small-mobile} {
    width: 65px;
    padding-bottom: 50px;

    & .slick-arrow {
      height: 20px;

      &.slick-prev {
        bottom: 25px;
      }
    }
  }
}

/* Product Summery */
.product-summery {
  --bs-warning-text-emphasis: #8a6d3b;
  --bs-success-text-emphasis: #3c763d;
  --bs-info-text-emphasis: #31708f;

  strong {
    @extend .fw-bold;
  }

  position: relative;

  &-center {
    text-align: center;

    & .product-ratings {
      justify-content: center;
    }

    & .product-variations {
      justify-content: center;

      & table {
        & tbody {
          & tr {
            & td {
              &.label {
                text-align: right;
              }

              &.value {
                text-align: left;
              }
            }
          }
        }
      }
    }

    & .product-buttons {
      justify-content: center;
    }

    & .product-brands {
      & .brands {
        justify-content: center;
      }
    }

    & .product-meta {
      justify-content: center;

      & table {
        & tbody {
          & tr {
            & td {
              &.label {
                text-align: right;
              }

              &.value {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

/* Product Navigation */
.product-nav {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-wrap: wrap;

  & a {
    padding: 8px 0 10px;

    color: $body-light;

    & + a {
      margin-left: 10px;
    }

    & i {
      font-size: 24px;
      line-height: 1;

      display: block;
    }

    &:hover {
      color: $heading-color;
    }
  }

  @media #{$large-mobile} {
    display: none;
  }
}

/* Product Rating */
.product-ratings {
  line-height: 1;

  display: flex;
  align-items: center;

  margin-bottom: 15px;
  padding: 13px 0;

  & .star-rating {
    font-size: 12px;

    position: relative;

    display: block;

    margin-right: 10px;

    &::before {
      font-family: $fontAwesome5Pro;
      font-size: 12px;
      font-weight: 300;
      line-height: 1;

      content: "    ";

      color: #ababab;
    }

    & .rating-active {
      font-size: 0;

      position: absolute;
      top: 0;
      left: 0;

      display: block;
      overflow: hidden;

      height: 100%;

      &::before {
        font-family: $fontAwesome5Pro;
        font-size: 12px;
        font-weight: 900;
        line-height: 1;

        content: "    ";
        white-space: nowrap;

        color: #f5cc26;
      }
    }
  }

  & .review-link {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;

    color: $body-light;

    & .count {
    }

    &:hover {
      color: $heading-color;
    }
  }
}

/* Product Title */
.product-title {
  font-weight: 400;
  line-height: 1.1675;
  font-size: 18px;

  margin-bottom: 25px;

  @media #{$small-mobile} {
    font-size: 36px;
  }

  @media #{$extra-small-mobile} {
    font-size: 30px;
  }
}

/* Product Price */
.product-price {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.175;

  display: block;

  margin-bottom: 25px;

  color: $heading-color;
}

/* Product Description */
.product-description {
  margin-bottom: 0.5rem;

  & p {
    line-height: 1.565;
  }
}

/* Group Product List */
.group-product-list {
  margin-bottom: 10px;

  & .info-text {
    font-size: 16px;
    font-weight: 500;

    display: block;

    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      font-weight: 700;
    }
  }

  & table {
    width: 100%;
    margin-bottom: 15px;

    border-top: 1px dotted #e5e5e5;

    &:last-child {
      margin-bottom: 0;
    }

    & tbody {
      & tr {
        & td {
          font-weight: 500;

          padding: 10px 10px 10px 0;

          border-width: 0 0 1px 0;
          border-style: dotted;
          border-color: #e5e5e5;
          background-color: transparent;

          &.choose {
            & input {
            }
          }

          &.quantity {
            font-size: 13px;

            color: $heading-color;
          }

          &.thumb {
            width: 50px;

            & img {
            }
          }

          &.title {
            font-size: 16px;
            font-weight: 500;

            & a {
            }
          }

          &.price {
            padding-right: 0;

            & .pro-price {
              display: block;

              text-align: right;

              & span {
                font-size: 16px;
                font-weight: 700;

                color: $heading-color;

                &.old {
                  font-size: 13px;

                  margin-right: 5px;

                  text-decoration: line-through;

                  opacity: 0.5;
                }

                &.new {
                }
              }
            }
          }
        }
      }
    }
  }

  @media #{$extra-small-mobile} {
    & table {
      & tbody {
        & tr {
          & td {
            &.price {
              & .pro-price {
                & span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Product Variations */
.product-variations {
  display: flex;

  margin-bottom: 30px;

  & .label {
    & span {
      font-size: 14px;
      font-weight: 400;

      letter-spacing: 1px;
      text-transform: uppercase;

      color: $heading-color;
    }
  }

  & table {
    & tbody {
      & tr {
        & td {
          // padding-top: 10px;
          // padding-bottom: 10px;

          &.label {
            @include media-breakpoint-up(md) {
              min-width: 160px;
              padding-right: 40px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    & table {
      & tbody {
        & tr {
          & td {
            &.label {
              width: 120px;
            }
          }
        }
      }
    }
  }
}

/* Product Sizes */
.product-sizes,
.product-variants__option {
  display: flex;

  &-buttons-img {
    width: 38px;
    height: 38px;
    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
    }
    @include media-breakpoint-up(lg) {
      width: 70px;
      height: 70px;
    }
  }

  & a,
  &-buttons {
    --bs-btn-disabled-border-color: transparent;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    margin-right: 15px;

    color: #ababab;

    &.disabled {
      pointer-events: auto;
      cursor: not-allowed;
      filter: opacity(0.5) brightness(0.8);
      background-color: $gray-200;

      &.selected {
        --bs-btn-disabled-border-color: #{$primary};
      }
    }

    &.selected {
      --bs-btn-border-color: #{$primary};
    }

    &:hover {
      --bs-btn-hover-border-color: --bs-btn-border-color;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $heading-color;
    }
  }

  @media #{$small-mobile} {
    & a {
      font-size: 18px;
    }
  }
}

/* Product Color */
.product-colors {
  display: flex;

  & a {
    width: 18px;
    height: 18px;
    margin-right: 15px;

    border: 1px solid #dddddd;
    border-radius: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* Product Quantity */
.product-quantity {
  font-weight: 600;

  position: relative;

  display: flex;
  align-items: center;

  max-width: 117px;
  padding: 10px 0;

  border-bottom: 2px solid #cccccc;

  & .qty-btn {
    font-size: 14px;
    line-height: 1;

    cursor: pointer;

    color: $heading-color;

    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }

    &.minus {
    }

    &.plus {
    }
  }

  & .input-qty {
    font-size: 14px !important;
    line-height: 1;

    max-width: 88px;
    padding: 0 15px 0px 15px !important;

    text-align: center;

    border: none !important;
  }
}

/* Product Action Buttons */
.product-buttons {
  display: flex;
  flex-wrap: wrap;

  margin-right: -15px;
  margin-bottom: 40px;
  margin-left: -15px;

  & .btn {
    margin-right: 15px;
    margin-bottom: 10px;
    margin-left: 15px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  @media #{$extra-small-mobile} {
    margin-right: -5px;
    margin-left: -5px;

    & .btn {
      margin-right: 5px;
      margin-left: 5px;

      &-icon {
        width: 50px;
        padding: 12px;
      }

      &:not(.btn-icon) {
        padding: 12px 30px;
      }
    }
  }
}

/* Product Brands */
.product-brands {
  margin-bottom: 30px;

  & .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;

    display: block;

    margin-bottom: 30px;

    text-transform: uppercase;

    color: $heading-color;
  }

  & .brands {
    display: flex;

    margin: 0 -20px;

    & a {
      margin: 0 20px;

      & img {
        opacity: 0.3;
      }

      &:hover {
        & img {
          opacity: 1;
        }
      }
    }
  }
}

/* Product Meta */
.product-meta {
  display: flex;

  margin-bottom: 20px;
  padding-top: 20px;

  border-top: 1px solid #e0e0e0;

  & .label {
    & span {
      font-weight: 400;

      letter-spacing: 1px;
      text-transform: uppercase;

      color: $heading-color;
    }
  }

  & table {
    & tbody {
      & tr {
        & td {
          // padding-top: 10px;
          // padding-bottom: 10px;

          &.label {
            min-width: 160px;
            padding-right: 40px;
          }

          &.value {
          }
        }
      }
    }
  }

  @media #{$small-mobile} {
    & table {
      & tbody {
        & tr {
          & td {
            &.label {
              width: 120px;
            }
          }
        }
      }
    }
  }
}

/* Product Category */
.product-category {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  & li {
    font-size: 16px;

    &:not(:last-child) {
      &::after {
        margin-right: 6px;

        content: ",";
      }
    }

    & a {
      &:hover {
        color: $heading-color;
      }
    }
  }
}

/* Product Tags */
.product-tags {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  & li {
    font-size: 16px;

    &:not(:last-child) {
      &::after {
        margin-right: 6px;

        content: ",";
      }
    }

    & a {
      &:hover {
        color: $heading-color;
      }
    }
  }
}

/* Product Share */
.product-share {
  display: flex;
  flex-wrap: wrap;

  & a {
    line-height: 1;

    display: flex;
    align-items: center;

    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    & i {
      font-size: 16px;
      line-height: 1;
    }

    &:hover {
      color: $heading-color;
    }
  }
}

/* Product Info Tab List */
.product-info-tab-list {
  justify-content: center;

  margin: 0 -20px 60px;

  & li {
    padding: 0 20px 20px;

    & a {
      font-size: 18px;
      font-weight: 400;
      line-height: 1;

      display: block;

      opacity: 0.2;
      color: $heading-color;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }

  @media #{$desktop-device,
 $tablet-device
} {
    margin-right: -15px;
    margin-left: -15px;

    & li {
      padding-right: 15px;
      padding-left: 15px;

      & a {
        font-size: 30px;
      }
    }
  }

  @media #{$large-mobile} {
    margin-right: -10px;
    margin-bottom: 30px;
    margin-left: -10px;

    & li {
      padding-right: 10px;
      padding-left: 10px;

      & a {
        font-size: 24px;
      }
    }
  }
}

/* Product Review Wrapper */
.product-review-wrapper {
  max-width: 820px;
  margin: auto;

  & > .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;

    display: block;

    margin-bottom: 40px;

    text-align: center;

    color: $heading-color;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Product Review List */
.product-review-list {
  margin: 0 0 70px;
  padding-left: 0;

  list-style: none;

  & li {
    margin-bottom: 40px;
    padding-bottom: 40px;

    border-bottom: 1px solid #e8e8e8;
  }

  @media #{$large-mobile} {
    margin-bottom: 50px;
  }
}

/* Product Review */
.product-review {
  display: flex;
  flex-wrap: wrap;

  & .thumb {
    overflow: hidden;
    align-self: flex-start;

    width: 70px;

    border-radius: 50%;

    & img {
      width: 100%;
    }
  }

  & .content {
    flex: 1 0 calc(100% - 70px);

    padding-left: 30px;

    & .ratings {
      line-height: 1;

      display: flex;
      align-items: center;

      margin-bottom: 15px;

      & .star-rating {
        font-size: 12px;

        position: relative;

        display: block;

        margin-right: 10px;

        &::before {
          font-family: $fontAwesome5Pro;
          font-size: 12px;
          font-weight: 300;
          line-height: 1;

          content: "    ";

          color: #ababab;
        }

        & .rating-active {
          font-size: 0;

          position: absolute;
          top: 0;
          left: 0;

          display: block;
          overflow: hidden;

          height: 100%;

          &::before {
            font-family: $fontAwesome5Pro;
            font-size: 12px;
            font-weight: 900;
            line-height: 1;

            content: "    ";
            white-space: nowrap;

            color: #f5cc26;
          }
        }
      }
    }

    & .meta {
      display: flex;
      align-items: center;

      margin-bottom: 20px;

      & .title {
        font-family: $futura;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;

        margin: 0;
      }

      & .date {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;

        margin-left: 12px;

        color: $heading-color;

        &::before {
          margin-right: 10px;

          content: "/";
        }
      }
    }

    & p {
    }
  }

  @media #{$extra-small-mobile} {
    flex-direction: column;

    & .content {
      padding-top: 20px;
      padding-left: 0;
    }
  }
}

/* Product Review Form */
.review-form {
  & .note {
    font-size: 16px;

    margin-bottom: 25px;
  }

  & form {
  }
}

/* Product Form Rating */
.form-rating {
  display: flex;
  flex-wrap: wrap;

  & .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: block;

    margin-right: 40px;
    margin-bottom: 0;

    letter-spacing: 1px;
    text-transform: uppercase;

    color: $heading-color;
  }

  & .rating {
    font-family: $fontAwesome5Pro;
    font-size: 12px;
    line-height: 1;

    position: relative;

    align-self: center;

    color: #f5cc26;

    &::before {
      content: "    ";
    }

    & .star {
      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;

      &::before {
        font-weight: 700;

        content: "    ";
      }
    }

    &:hover {
      & .star {
        opacity: 1;
      }
    }
  }
}
