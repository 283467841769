/* 03.08. Swiper Slider */

.swiper-slider-wrapper {
    position: relative;

    overflow: hidden;

    margin-right: -40px;
    margin-left: -40px;
    padding-right: 40px;
    padding-left: 40px;

    & .swiper-button-prev {
        left: -40px;
    }

    & .swiper-button-next {
        right: -40px;
    }

    &:hover {
        & [class*="swiper-button"] {
            opacity: 1;
        }

        & .swiper-button-prev {
            left: 0;
        }

        & .swiper-button-next {
            right: 0;
        }
    }
}

[class*="swiper-button"] {
    width: 40px;
    height: 40px;
    margin-top: 0 !important;

    transition: $transition;
    transform: translateY(-50%);

    opacity: 0;
    color: $body-color;

    & i {
        font-size: 25px;
        line-height: 1;

        transition: $transition;
    }

    &::after {
        display: none !important;
    }

    &:hover {
        color: $heading-color;
    }
}

.swiper-pagination {
    position: static;

    display: flex;
    align-items: center;
    justify-content: center;

    max-height: 20px;
    margin-top: 35px;

    & .swiper-pagination-bullet {
        flex: 1 0 auto;

        width: 10px;
        max-width: 10px;
        height: 10px;
        max-height: 10px;
        margin: 6px !important;

        transition: $transition;

        border: 1px solid transparent;
        background-color: #1F1F1F;

        &-active {
            width: 18px;
            max-width: 18px;
            height: 18px;
            max-height: 18px;

            border-color: $heading-color;
            background-color: transparent;
        }
    }
}

.swiper--hover-show-btn{
    .swiper-button-prev{
        left: -40px;
    }
    .swiper-button-next{
        right: -40px;
    }
    &:hover{
        .swiper-button-prev{
            left: 0;
            opacity: 1;
        }
        .swiper-button-next{
            right: 0;
            opacity: 1;
        }
    }
}