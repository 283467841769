/* 18.03. Contact Page CSS */

/* Contact Information */
.contact-info {
    & .title {
        font-family: $futura;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;

        margin-bottom: 15px;
        padding-left: 44px;
    }

    & .info {
        position: relative;

        display: block;

        padding-left: 44px;

        &:not(:last-child) {
            margin-bottom: 5px;
        }

        & .icon {
            font-size: 22px;
            line-height: 32px;

            position: absolute;
            top: 0;
            left: 0;

            color: #D8D8D8;
        }

        & a {
        }
    }
}

/* Contact Map */
.contact-map {
    width: 100%;
    height: 500px;

    @media #{$large-mobile} {
        height: 350px;
    }
}