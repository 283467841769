.bg {
  &-position--center {
    background-position: center;
  }

  &-repeat-n {
    background-repeat: no-repeat;
  }

  &-size--cover {
    background-size: cover;
  }
}

.bg-image--paw {
  background-image: url("../images/page-title/paw-bg.png");
}

@mixin line-clamp($maxline-value) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $maxline-value;
  -webkit-box-orient: vertical;
  /** Usage
  /* @include line-clamp();
  */
}

@function px-to-rem($px-value, $base-font-size: 16px) {
  @return calc($px-value / $base-font-size) * 1rem;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }

  &-none {
    cursor: none;
  }

  &-default {
    cursor: default;
  }
}

[data-action-layout="item"] {
  transition: all 0.4s;
}
.pointer-events-n {
  pointer-events: none;
}
