/* 08.03. Parallax Banner CSS */
.parallax-banner {
    position: relative;

    display: flex;
    overflow: hidden;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    min-height: 100vh;

    & .parallax-image {
        position: absolute;
        z-index: -1;

        display: block;

        width: 100%;
        height: 100%;

        & img {
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;

            min-width: 100%;
            min-height: 100%;

            transform: translate(-50%, -50%);

            object-fit: cover;
        }
    }

    & .content {
        flex: 1 0 50%;

        max-width: 50%;
    }

    &-1 {
        justify-content: center;

        & .content {
            flex: 1 0 100%;

            max-width: 100%;

            transform: translateY(-100%);

            & .sub-title {
                font-family: $modesty;
                font-size: 120px;
                line-height: 0.75;

                display: block;

                color: $heading-color;
            }

            & .title {
                font-family: $futura;
                font-size: 56px;
                font-weight: 600;
                line-height: 1.25;

                margin: 0;

                letter-spacing: 20px;

                color: $primary3;
            }
        }
    }

    &-2 {
        justify-content: flex-end;

        & .content {
            & .price {
                margin-bottom: 35px;
            }

            & .title {
                font-size: 64px;
                font-weight: 400;
                line-height: 1.25;

                margin-bottom: 20px;
            }

            & .sub-title {
                font-family: $modesty;
                font-size: 72px;
                line-height: 1;

                display: block;

                color: $primary2;
            }
        }
    }

    &-3 {
        & .content {
            & .sub-title {
            }

            & .title {
                font-size: 64px;
                font-weight: 400;
                line-height: 1.25;

                margin-bottom: 30px;
            }
        }
    }

    &-4 {
        & .content {
            max-width: 500px;
            margin-left: 15%;

            & .sub-title {
                font-family: $modesty;
                font-size: 80px;
                line-height: 1;

                display: block;

                margin-bottom: 10px;

                color: $heading-color;
            }

            & .title {
                font-size: 48px;
                font-weight: 400;
                line-height: 1.35;

                margin-bottom: 30px;
            }

            & a {
                font-family: $futura;
                font-size: 16px;
                font-weight: 400;
                line-height: 1;

                letter-spacing: 1px;
                text-transform: uppercase;

                color: $heading-color;
            }
        }
    }

    @media #{$laptop-device} {
        &-4 {
            & .content {
                margin-left: 5%;
            }
        }
    }

    @media #{$desktop-device,
    $tablet-device,
    $large-mobile} {
        &-1 {
            & .content {
                & .sub-title {
                    font-size: 100px;
                }

                & .title {
                    font-size: 44px;
                }
            }
        }

        &-2 {
            & .content {
                & .title {
                    font-size: 44px;
                }

                & .sub-title {
                    font-size: 56px;
                }
            }
        }

        &-3 {
            & .content {
                & .sub-title {
                }

                & .title {
                    font-size: 44px;
                }
            }
        }

        &-4 {
            & .content {
                max-width: 420px;
                margin-left: 5%;

                & .sub-title {
                    font-size: 60px;
                }

                & .title {
                    font-size: 40px;
                }
            }
        }
    }

    @media #{$large-mobile} {
        & .content {
            flex: 1 0 100%;

            max-width: 100%;
        }

        &-1 {
            & .content {
                & .sub-title {
                    font-size: 80px;
                }

                & .title {
                    font-size: 40px;

                    letter-spacing: 10px;
                }
            }
        }

        &-4 {
            & .content {
                max-width: 350px;
            }
        }
    }

    @media #{$extra-small-mobile} {
        &-2 {
            & .content {
                & .title {
                    font-size: 36px;
                }

                & .sub-title {
                    font-size: 44px;
                }
            }
        }

        &-3 {
            & .content {
                & .title {
                    font-size: 36px;
                }
            }
        }

        &-4 {
            & .content {
                & .sub-title {
                    font-size: 50px;
                }

                & .title {
                    font-size: 36px;
                }
            }
        }
    }
}