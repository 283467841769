.grid-1-cols {
    grid-template-columns: repeat(1, 1fr);
}

.grid-2-cols {
    grid-template-columns: repeat(2, 1fr);
}

.grid-md-3-cols {
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-30percent {
        flex: 0 0 auto;
        width: 31.3%;
    }
}

.mx-vh-75 {
    max-height: 75vh;
}


/* moolight modal */
.modal__logo--align-top {
    left: calc(50% - 75px);
    top: -130px;
    width: 150px;
}

.calculator__icon-pet--color {
    --bs-btn-active-bg: #{$brand};
    --bs-btn-active-border-color: #{$brand};
    --bs-btn-color:#{$brand};
    --bs-btn-border-color:#{$brand};
    --bs-btn-active-color: white;
    background-color: white;
    color: $brand;
}

.calculator__age-input[type="number"] {
    @extend .text-center;
    width: 50%;
    max-width: 100px;

    &.narrow {
        max-width: 80px;

        @include media-breakpoint-up(sm) {
            max-width: auto;
        }
    }

    @include media-breakpoint-up(sm) {
        width: auto;
        max-width: auto;
    }
}

.modal--close-btn__size {
    width: 30px;
    height: 30px;
    font-size: 30px;
}