/* accordion */
$accordion-border-width: 0px;
$accordion-border-radius: 0px;
$accordion-button-padding-y: .5rem;

$gray-light: lighten(#000, 46.7%);
$primary: rgb(54, 10, 110);

//* Link hover color set via `darken()` function.
$link-hover-color: #3e1240;

//== Typography

$font-family-sans-serif: "Rubik",
"Roboto",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$font-family-base: $font-family-sans-serif;

//== Components
$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

//== Buttons
$btn-primary-color: #fff;
$btn-primary-bg: $primary;
//* Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//* Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1200px+70px;
$grid-gutter-width: 30px;

// Medium screen / desktop
$container-large-desktop: ((1140px + 70px + $grid-gutter-width));

$navbar-inverse-color: darken(#fff, 30%);
$navbar-inverse-bg: #400d41;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: darken(#fff, 30%);
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: #4B164E;
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;


$enable-negative-margins: true;

$list-group-active-bg: #c0afd0;
$list-group-item-padding-y: 1rem;

$utilities: ("text-transform": (property: text-transform,
        class: text,
        values: lowercase uppercase capitalize none),
);
/* modal */
$modal-md:600px;

/* breadcrumb */
$breadcrumb-font-size: .875rem;