/*----------------------------------------*/
/*  Font semplicita
/*----------------------------------------*/
@font-face {
  font-family: "semplicita";
  font-weight: normal;
  font-style: normal;

  font-display: swap;
  src: url("../fonts/semplicita-medium.otf");
  // src: url("../fonts/semplicita-medium.eot");
  // src: url("../fonts/semplicita-medium.eot?#iefix") format("embedded-opentype"),
  // url("../fonts/semplicita-medium.woff2") format("woff2"),
  // url("../fonts/semplicita-medium.woff") format("woff"),
  // url("../fonts/semplicita-medium.ttf") format("truetype"),
  // url("../fonts/semplicita-medium.svg#ModestyRegular") format("svg");
}

/*----------------------------------------*/
/*  Font awesome
/*----------------------------------------*/
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../fonts/font-awesome-pro/fa-regular-400.eot);
  src: url(../fonts/font-awesome-pro/fa-regular-400.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/font-awesome-pro/fa-regular-400.woff2) format("woff2"),
    url(../fonts/font-awesome-pro/fa-regular-400.woff) format("woff"),
    url(../fonts/font-awesome-pro/fa-regular-400.ttf) format("truetype"),
    url(../fonts/font-awesome-pro/fa-regular-400.svg#fontawesome) format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(../fonts/font-awesome-pro/fa-light-300.eot);
  src: url(../fonts/font-awesome-pro/fa-light-300.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/font-awesome-pro/fa-light-300.woff2) format("woff2"),
    url(../fonts/font-awesome-pro/fa-light-300.woff) format("woff"),
    url(../fonts/font-awesome-pro/fa-light-300.ttf) format("truetype"),
    url(../fonts/font-awesome-pro/fa-light-300.svg#fontawesome) format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(../fonts/font-awesome-pro/fa-brands-400.eot);
  src: url(../fonts/font-awesome-pro/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/font-awesome-pro/fa-brands-400.woff2) format("woff2"),
    url(../fonts/font-awesome-pro/fa-brands-400.woff) format("woff"),
    url(../fonts/font-awesome-pro/fa-brands-400.ttf) format("truetype"),
    url(../fonts/font-awesome-pro/fa-brands-400.svg#fontawesome) format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../fonts/font-awesome-pro/fa-solid-900.eot);
  src: url(../fonts/font-awesome-pro/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/font-awesome-pro/fa-solid-900.woff2) format("woff2"),
    url(../fonts/font-awesome-pro/fa-solid-900.woff) format("woff"),
    url(../fonts/font-awesome-pro/fa-solid-900.ttf) format("truetype"),
    url(../fonts/font-awesome-pro/fa-solid-900.svg#fontawesome) format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../fonts/font-awesome-pro/fa-duotone-900.eot);
  src: url(../fonts/font-awesome-pro/fa-duotone-900.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/font-awesome-pro/fa-duotone-900.woff2) format("woff2"),
    url(../fonts/font-awesome-pro/fa-duotone-900.woff) format("woff"),
    url(../fonts/font-awesome-pro/fa-duotone-900.ttf) format("truetype"),
    url(../fonts/font-awesome-pro/fa-duotone-900.svg#fontawesome) format("svg");
  font-display: swap;
  font-display: swap;
}
/* Marcellus */
@font-face {
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/marcellus/marcellus-regular.eot");
  src: url("../fonts/marcellus/marcellus-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/marcellus/marcellus-regular.woff2") format("woff2"),
    url("../fonts/marcellus/marcellus-regular.woff") format("woff"),
    url("../fonts/marcellus/marcellus-regular.ttf") format("truetype");
}

/* Charmonman Regular */
@font-face {
  font-family: "Charmonman";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/charmonman/charmonman-regular.eot");
  src: url("../fonts/charmonman/charmonman-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/charmonman/charmonman-regular.woff2") format("woff2"),
    url("../fonts/charmonman/charmonman-regular.woff") format("woff"),
    url("../fonts/charmonman/charmonman-regular.ttf") format("truetype");
}

/* Charmonman Bold */
@font-face {
  font-family: "Charmonman";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/charmonman/charmonman-bold.eot");
  src: url("../fonts/charmonman/charmonman-bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/charmonman/charmonman-bold.woff2") format("woff2"),
    url("../fonts/charmonman/charmonman-bold.woff") format("woff"),
    url("../fonts/charmonman/charmonman-bold.ttf") format("truetype");
}

/* Rubik Light */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/rubik/rubik-light.eot");
  src: url("../fonts/rubik/rubik-light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rubik/rubik-light.woff2") format("woff2"),
    url("../fonts/rubik/rubik-light.woff") format("woff"),
    url("../fonts/rubik/rubik-light.ttf") format("truetype");
}

/* Rubik Regular */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/rubik/rubik-regular.eot");
  src: url("../fonts/rubik/rubik-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/rubik/rubik-regular.woff2") format("woff2"),
    url("../fonts/rubik/rubik-regular.woff") format("woff"),
    url("../fonts/rubik/rubik-regular.ttf") format("truetype");
}

/* Rubik Medium */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/rubik/rubik-medium.eot");
  src: url("../fonts/rubik/rubik-medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rubik/rubik-medium.woff2") format("woff2"),
    url("../fonts/rubik/rubik-medium.woff") format("woff"),
    url("../fonts/rubik/rubik-medium.ttf") format("truetype");
}

/* Rubik SemiBold */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/rubik/rubik-semibold.eot");
  src: url("../fonts/rubik/rubik-semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/rubik/rubik-semibold.woff2") format("woff2"),
    url("../fonts/rubik/rubik-semibold.woff") format("woff"),
    url("../fonts/rubik/rubik-semibold.ttf") format("truetype");
}

/* Rubik Bold */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/rubik/rubik-bold.eot");
  src: url("../fonts/rubik/rubik-bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rubik/rubik-bold.woff2") format("woff2"),
    url("../fonts/rubik/rubik-bold.woff") format("woff"),
    url("../fonts/rubik/rubik-bold.ttf") format("truetype");
}

/* Rubik ExtraBold */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/rubik/rubik-extrabold.eot");
  src: url("../fonts/rubik/rubik-extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/rubik/rubik-extrabold.woff2") format("woff2"),
    url("../fonts/rubik/rubik-extrabold.woff") format("woff"),
    url("../fonts/rubik/rubik-extrabold.ttf") format("truetype");
}

/* Rubik Black */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/rubik/rubik-black.eot");
  src: url("../fonts/rubik/rubik-black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rubik/rubik-black.woff2") format("woff2"),
    url("../fonts/rubik/rubik-black.woff") format("woff"),
    url("../fonts/rubik/rubik-black.ttf") format("truetype");
}

/* Rubik Light Italic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/rubik/rubik-lightitalic.eot");
  src: url("../fonts/rubik/rubik-lightitalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/rubik/rubik-lightitalic.woff2") format("woff2"),
    url("../fonts/rubik/rubik-lightitalic.woff") format("woff"),
    url("../fonts/rubik/rubik-lightitalic.ttf") format("truetype");
}

/* Rubik Regular Italic */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/rubik/rubik-italic.eot");
  src: url("../fonts/rubik/rubik-italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rubik/rubik-italic.woff2") format("woff2"),
    url("../fonts/rubik/rubik-italic.woff") format("woff"),
    url("../fonts/rubik/rubik-italic.ttf") format("truetype");
}
