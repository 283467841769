/* Block Title */
.block-title {
    margin-bottom: 35px;
    padding-bottom: 10px;

    border-bottom: 2px solid #EDEDED;

    & .title {
        font-size: 30px;
        font-weight: 400;
        line-height: 1;

        margin: 0;
    }
}